@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("navbar.css");
@import url("deleted-text.css");
@import url("footer.css");
@import url("home.css");
@import url("destroyLink.css");
@import url("password.css");
@import url("responsive.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none; /* remove the resize handle on the bottom right */
}

.body {
  max-width: 740px;
  margin: 0 auto;
  min-height: 360px;
  font-family: Poppins;
}

::-webkit-scrollbar {
  display: none;
}

::file-selector-button {
  display: none;
}

.btn-loader {
  display: flex;

  /* border: 4px solid #f3f3f3; */
  border-top: 4px solid #f9d15f;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.4s linear infinite;
  margin: 0 auto;
}

.loader-wrapper {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-loader {
  display: flex;
  border: 4px solid #f9d15f;
  border-top: 4px solid #e87171;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.4s linear infinite;
  margin: 0 auto;
}

.note-loader {
  display: flex;

  /* border: 4px solid #f3f3f3; */
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.3s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
